<template>

    <div id="app" class="temp-wrapper-size">

        <!-- Notifications -->
        <notification
            v-if="notifications.length > 0"
            :messages="notifications"
        />

        <!-- App installation -->
        <button
            class="install-prompt notification__btn"
            type="button"
            v-if="defferedInstallPrompt"
            @click="clickInstallPromptBtn"
        >
            Install me
        </button>

        <!-- Header -->
        <header class="header">

            <div class="header__row">
                <h1 class="h h1 nowrap">Can I Stop</h1>
                <div class="header__end | flex-center">
                    <theme-switcher />
                    <a href="#about">About</a>
                </div>
            </div>

            <p v-if="month"><strong>{{ browser.name }} {{ browser.version }}</strong> usage during <time :datetime="datetimeAttr">{{ month }}</time>.</p>

            <button
                class="browsers-toggle-btn | btn btn--selected flex-center"
                type="button"
                v-show="!browsersSelectionVisible"
                @click="onClickForBrowsersSelector"
            >
                Other browsers
                <svg class="notification__btn__icon btn__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9" width="9">
                    <use xlink:href="#chevron-path" />
                </svg>
            </button>

            <regions-share />

            <browsers-selector
                v-show="browsersSelectionVisible"
                @requestVisibilityToggle="onClickForBrowsersSelector"
            />

            <!-- <nav class="none">
              <ul>
                  <li>
                      <button
                          type="button"
                          @click="toggleAboutVisibility(true)"
                      >
                          About
                      </button>
                  </li>
              </ul>
          </nav> -->
        </header>

        <!-- App -->
        <main id="canistop">
            <regions-count />

            <filters />

            <regions v-if="filteredRegions.length" :regions="filteredRegions" />

            <p class="x-space" v-show="!filteredRegions.length">
                No regions
                <span v-if="filter.length">matching <em>{{ filter }}</em></span>
                where {{ browser.name }}&nbsp;{{ browser.version }} has at least {{ threshold }}% market shares.
            </p>

        </main>

        <!-- About -->
        <!-- <about class="none" id="sidebar">
          <button
              type="button"
              @click="toggleAboutVisibility(false)"
          >Close</button>
      </about> -->

        <!-- Footer -->
        <footer id="about" class="footer">
            <a class="footer__close | btn" href="#">Close</a>
            <p>I’m <a href="https://mehdi.cc" target="_blank" rel="noopener">Mehdi</a>, a front-end developer. I made this <a href="https://gitlab.com/meduzen/canistop" target="_blank" rel="noopener">open-source website</a> in 2019 to learn <a href="https://vuejs.org">Vue.js</a>. The <a href="https://github.com/Fyrd/caniuse/tree/main/region-usage-json" target="_blank" rel="noopener">browsers usage data</a> comes from the super useful <a href="https://caniuse.com" target="_blank" rel="noopener"><em>Can I Use</em></a> while the <a href="https://github.com/mdn/browser-compat-data/tree/main/browsers" target="_blank" rel="noopener">browsers metadata</a> used in the browser and version selector comes from <a href="https://developer.mozilla.org" target="_blank" rel="noopener"><abbr title="Mozilla Developer Network">MDN</abbr></a>.</p>
            <p>The colors are inspired from the <a href="https://en-americas-support.nintendo.com/app/answers/detail/a_id/22357/~/how-to-change-the-theme" target="_blank" rel="noopener">Nintendo Switch themes</a>.</p>
            <hr>
            <p><span aria-hidden="true">💡</span> Get a monthly data update notification on <a href="https://m.nintendojo.fr/@canistop" target="_blank" rel="noopener"><strong>m.nintendojo.fr/@canistop</strong></a> (Mastodon). Thanks to <a href="https://laravel.com" target="_blank" rel="noopener">Laravel</a> to make this possible. Later, you’ll be able to receive push notifications in your browser.</p>
        </footer>

    </div>

</template>

<script>
import { datetime } from 'datetime-attribute'
import { mapState, mapGetters, mapActions } from 'vuex'

import store from './store'

import BrowsersSelector from './components/browsers/BrowsersSelector.vue'
import Filters from './components/filters/Filters.vue'
import Regions from './components/regions/Regions.vue'
import RegionsCount from './components/regions/RegionsCount.vue'
import RegionsShare from './components/regions/RegionsShare.vue'
import Notification from './components/notification/Notification.vue'

// import About from './About.vue'
import ThemeSwitcher from './components/theme-switcher/ThemeSwitcher.vue'

export default {

  components: {
    // About,
    BrowsersSelector,
    Filters,
    Regions,
    RegionsCount,
    RegionsShare,
    ThemeSwitcher,
    Notification,
  },

  data () {
    return {
      defferedInstallPrompt: null,
      notifications: [],
      fetchedMonth: null,
      browsersSelectionVisible: false,
    }
  },

  store,

  computed: {
    ...mapState({
      month: state => state.date.month,
      threshold: state => state.filters.threshold.threshold,
      filter: state => state.filters.text.text,
      allFiltersVisible: state => state.layout.allFiltersVisible,
      // aboutIsVisible: state => state.about.aboutIsVisible,
    }),

    ...mapGetters([
      'filteredRegions',
      'isDifferentMonth',
    ]),

    ...mapGetters({
      browser: 'currentBrowser',
    }),

    isNewMonth() {
      return this.isDifferentMonth(this.fetchedMonth)
    },

    datetimeAttr() {
      const date = new Date(this.month)
      return datetime(date, 'month')
    },
  },

  watch: {
    isNewMonth() {
      this.notifications.push({
        'title': 'Fresh data found!',
        'detail': this.fetchedMonth,
      })
    }
  },

  async created() {
    this.installServiceWorker()
    this.loadFilters()
    this.setOnline()
    this.loadAltLanguageDir()
    await this.fetchMetadata()
    this.fetchData()

    window.addEventListener('online', this.setOnline)
    window.addEventListener('offline', this.setOnline)

    document.addEventListener('keydown', this.onEscapeKey)
  },

  methods: {
    ...mapActions([
      'fetchData',
      'fetchMetadata',
      'loadAltLanguageDir',
      'loadFilters',
      // 'toggleAboutVisibility',
      'toggleAllFiltersVisibility',
      'setOnline',
      'setCachedBrowsers',
    ]),

    installServiceWorker() {
      if (!('serviceWorker' in navigator)) { return }

      // Register Service Worker.
      if (!navigator.serviceWorker.controller) {
        navigator.serviceWorker.register('/service-worker.js')
      }

      // Delay “add to home screen” prompt.
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()
        this.defferedInstallPrompt = e
      })

      // Listen to messages from Service Worker.
      navigator.serviceWorker.addEventListener('message', ({ data }) => {
        if ('date' in data) {
          this.fetchedMonth = data.date

          if (this.isNewMonth) {
            // Request data cache update to Service Worker.
            navigator.serviceWorker.controller.postMessage({ action: 'updateDataCache' })
          }
        }

        if ('appUpdate' in data) {
          this.notifications.push({
            'title': 'Unlike IE11, we ship! 🚀',
            'detail': 'App updated!',
          })
        }

        // if ('dataCacheUpdate' in data) {
        //   console.log('All cached data have been updated', data)
        // }

        if ('cachedBrowsers' in data) {
          this.setCachedBrowsers(data.cachedBrowsers)
        }
      })
    },

    onEscapeKey(e) {
      if (e.keyCode != 27) { return }

      if (this.browsersSelectionVisible) {
        return this.toggleBrowsersSelectionVisibility(false)
      }

      if (location.hash == '#about') {
        return location.hash = ''
      }

      if (this.allFiltersVisible) {
        this.toggleAllFiltersVisibility(false)
      }
    },

    onClickForBrowsersSelector() {
      this.toggleBrowsersSelectionVisibility()
    },

    toggleBrowsersSelectionVisibility(state = !this.browsersSelectionVisible) {
      this.browsersSelectionVisible = state
      document.documentElement.classList.toggle('scrollblock', state)
    },

    clickInstallPromptBtn() {
      this.defferedInstallPrompt.prompt()

      this.defferedInstallPrompt.userChoice.then(choice => {
        if (choice.outcome == 'accepted') {
          this.defferedInstallPrompt = null
        }
      })
    },
  },
}
</script>
