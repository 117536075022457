<template>
    <h2 class="regions-count | h h2">
        <a class="regions-count__shown" href="#canistop" title="Start of the regions list">{{ nbShown }} region{{ nbShown > 1 ? 's' : '' }}</a><small class="regions-count__not-shown" v-if="nbNotShown">, {{ nbNotShown }} not shown</small>

        <svg class="regions-count__arrow sorter__arrow sorter__arrow--asc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 15" width="8">
            <use class="arrow-svg" xlink:href="#arrow-path" />
        </svg>
    </h2>
</template>

<script>
export default {
  computed: {
    nbShown() {
      return this.$store.getters.filteredRegions.length
    },

    nbNotShown() {
      return this.$store.state.regions.all.length - this.nbShown
    },
  },

  mounted() {
    this.handleStickiness()
    window.addEventListener('scroll', this.handleStickiness, { passive: true })
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleStickiness, { passive: true })
  },

  methods: {
    /**
     * The condition below should be `== 0` instead of `< 1` but
     * `getBoundingClientRect().top` on Safari iOS returns
     * either 0 either -0.5 when `$el` becomes sticky.
     */
    handleStickiness() {
      const shouldStick = this.$el.getBoundingClientRect().top < 1
      document.documentElement.classList.toggle('sticky-regions-and-sorters', shouldStick)
    },
  },
}
</script>
