/**
 * Returns browser name and version from URL fragments.
 *
 * A typical URL pathname is like: `/b/ie/11` with or
 * without trailing slash.
 */
export function getBrowserFromUrl() {
  const urlFragments = (location.pathname.split('/', 4))

  if (
    urlFragments.length < 3
    || urlFragments[1] != 'b'
    || urlFragments[2].length == 0
  ) {
    return null
  }

  return {
    key: urlFragments[2],
    version: urlFragments[3] || null,
  }
}

const browserVersionDefault = {
  release_date: null,
  status: null,
  version: null,
}

export function normalizeBrowserVersions(initialVersions) {
  const versions = []

  Object.entries(initialVersions).forEach(entry => {
    const valueType = typeof (entry[1])

    // No key, value is the version.
    if (valueType == 'string' || valueType == 'number') {
      return versions.push({ ...browserVersionDefault, ...{ version: entry[1] } })
    }

    // Key is the version, but value is `null`.
    if (entry[1] === null) {
      return versions.push({ ...browserVersionDefault, ...{ version: entry[0] } })
    }

    // Object already clean, only needs Date instantiation.
    const releaseDate = entry[1].release_date ? new Date(entry[1].release_date) : null
    versions.push({ ...entry[1], ...{ release_date: releaseDate } })
  })

  return versions.sort((a, b) => {
    let floatA = parseFloat(a.version)
    const isNumberA = floatA == a.version

    let floatB = parseFloat(b.version)
    const isNumberB = floatB == b.version

    if (!isNumberA) {
      floatA = a.version.split('-')[0] // lower limit of versions range
    }

    if (!isNumberB) {
      floatB = b.version.split('-')[0]
    }

    return floatB - floatA
  })
}
