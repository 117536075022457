<template>

    <div class="browsers-ctn">

        <!-- Browsers selector -->

        <div class="browsers">

            <!-- Nav -->

            <div class="browsers__nav">

                <!-- Close browsers selector -->

                <button
                    class="browsers__back | btn btn--no-border flex-center"
                    type="button"
                    @click="reset"
                >
                    <span class="btn__icon | flex-center">
                        <svg class="back__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 15" width="12">
                            <title>Back to data</title>
                            <use class="arrow-svg" xlink:href="#arrow-path" />
                        </svg>
                    </span>
                    <!-- Back -->
                </button>

                <!-- Show browsers names list -->

                <button
                    class="btn btn--no-border flex-center nowrap"
                    :class="{ 'btn--selected': showBrowsersFieldset }"
                    type="button"
                    @click="toggleBrowsersFieldsetVisibility(true)"
                >
                    {{ selectedBrowserName }}
                </button>

                <!-- Show browsers versions list -->

                <button
                    class="btn btn--no-border flex-center nowrap"
                    :class="{ 'btn--selected': !showBrowsersFieldset }"
                    type="button"
                    @click="toggleBrowsersFieldsetVisibility(false)"
                >
                    {{ selectedVersion }}
                </button>
            </div>

            <!-- Form -->

            <form
                class="browsers__form"
                :class="{ 'browsers__form--with-button': !showBrowsersFieldset }"
                action=""
                @submit.prevent
            >

                <!-- Browsers names -->

                <browsers
                    :browsers="browsers"
                    :selected-browser="selectedBrowser"
                    v-if="showBrowsersFieldset"
                    @selectBrowser="selectBrowser"
                />

                <!-- Browsers versions -->

                <versions
                    :versions="versions"
                    :selected-version="selectedVersion"
                    v-if="!showBrowsersFieldset"
                    @selectVersion="selectVersion"
                />

                <!-- Load data for selected browser+version -->

                <button
                    class="browser__load notification__btn | btn btn--selected flex-center"
                    @click="load"
                    v-if="!showBrowsersFieldset"
                >
                    <span>
                        Load
                        <span class="nowrap">{{ selectedBrowserName }} {{ selectedVersion }}</span>
                    </span>
                </button>

            </form>

        </div>

    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Browsers from './Browsers.vue'
import Versions from './Versions.vue'

export default {
  components: {
    Browsers,
    Versions,
  },

  data() {
    return {
      selectedBrowser: null,
      selectedVersion: null,
      versions: null,
      browsersSelectionVisible: false,
      showBrowsersFieldset: true,
    }
  },

  computed: {
    ...mapGetters({
      browsers: 'availableBrowsers',
      current: 'currentBrowser',
    }),

    selectedBrowserName() {
      return this.selectedBrowser
        ? this.browsers.find(browser => browser.key == this.selectedBrowser)?.name ?? ''
        : ''
    },
  },

  watch: {
    browsers(browsers) {
      this.selectedBrowser = this.current.key

      let newCurrent = browsers.find(browser => browser.key == this.selectedBrowser)
      if (newCurrent) {
        this.versions = newCurrent.versions
      }
    },

    current(current) {
      this.selectedBrowser = current.key
      this.versions = this.browsers.find(browser => browser.key == current.key).versions
      this.selectedVersion = current.version
    },
  },

  methods: {
    ...mapActions([
      'setBrowser',
    ]),

    toggleBrowsersFieldsetVisibility(state = !this.showBrowsersFieldset) {
      this.showBrowsersFieldset = state
    },

    selectBrowser(e) {
      this.selectedBrowser = e.target.value
      this.versions = this.browsers.find(browser => browser.key == this.selectedBrowser).versions

      this.selectedVersion =
          this.selectedBrowser == this.current.key
            ? this.current.version
            : this.versions[0].version // should be replaced by “current”

      /**
       * e.detail: `1` on <radio> selection with a mouse, `0` with a keyboard.
       * // https://codepen.io/meduzen/pen/QWjrEWx
       */
      if (e.detail) {
        this.toggleBrowsersFieldsetVisibility(false)
      }
    },

    selectVersion(e) {
      this.selectedVersion = e.target.value
    },

    load() {
      this.setBrowser({
        key: this.selectedBrowser,
        version: this.selectedVersion,
      })

      this.reset()

      /**
       * @todo: Once loaded (fetched, precisely), close the browsers selector.
       * @todo: This should be moved to the **store**.
       */
    },

    reset() {
      this.selectedBrowser = this.current.key
      this.selectedVersion = this.current.version
      this.toggleBrowsersFieldsetVisibility(true)

      this.$emit('requestVisibilityToggle')
    },
  },
}
</script>
