<template>

    <fieldset>
        <div class="threshold">

            <label class="threshold__label" for="threshold">
                <slot />
                <input
                    class="threshold__input"
                    type="number" step="0.1" min="0" max="100"
                    id="threshold" name="threshold"
                    v-model="threshold"
                    @focusin="onFocusIn"
                    aria-label="Threshold"
                >
                %
            </label>

            <div class="threshold__controls">
                <button
                    class="threshold__btn threshold__btn--decrement | flex-middle"
                    type="button"
                    @click="threshold -= .1"
                    @touchstart="startDecrementing"
                    @mousedown="startDecrementing"
                    @touchend="stopDecrementing"
                    @mouseup="stopDecrementing"
                    @mouseleave="stopDecrementing"
                >
                    <span class="visually-hidden">Decrement</span>
                    <span class="threshold__btn__label" css-label="-" />
                </button>

                <button
                    class="threshold__btn threshold__btn--increment | flex-middle"
                    type="button"
                    @click="threshold += .1"
                    @touchstart="startIncrementing"
                    @mousedown="startIncrementing"
                    @touchend="stopIncrementing"
                    @mouseup="stopIncrementing"
                    @mouseleave="stopIncrementing"
                >
                    <span class="visually-hidden">Increment</span>
                    <span class="threshold__btn__label" css-label="+" />
                </button>
            </div>

        </div>
    </fieldset>

</template>

<script>
export default {
  data () {
    return {
      decrementInterval: null,
      incrementInterval: null,
    }
  },

  computed: {
    threshold: {
      get () {
        return this.$store.state.filters.threshold.threshold
      },
      set (value) {
        this.$store.dispatch('threshold', parseFloat(value))
      },
    }
  },

  methods: {
    onFocusIn() {
      this.$store.dispatch('selectedOnly', false)
    },

    startDecrementing() {
      if (!this.decrementInterval) {
        this.decrementInterval = setInterval(() => this.threshold -= .1, 100)
      }
    },

    stopDecrementing() {
      clearInterval(this.decrementInterval)
      this.decrementInterval = null
    },

    startIncrementing() {
      if (!this.incrementInterval) {
        this.incrementInterval = setInterval(() => this.threshold += .1, 100)
      }
    },

    stopIncrementing() {
      clearInterval(this.incrementInterval)
      this.incrementInterval = null
    },
  },
}
</script>
