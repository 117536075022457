import { createUrl } from '../helpers/url'

const actions = {
  updateHistory ({ getters }) {
    history.replaceState(null, document.title, getters.url)
  },
}

const getters = {
  url: (state, getters, rootState, rootGetters) =>
    createUrl(rootGetters.browserPathname, [rootGetters.regionsUrlParam]),
}

export default { actions, getters }
