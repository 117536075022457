import { idbLoad, idbSave } from '../../helpers/idb'
import { THEME_MQ, CSS, THEMES, ICONS } from './config'

function supportColorSchemeMQ() {
  return matchMedia(THEME_MQ).media == THEME_MQ
}

function getIconHtml(theme, icon) {
  return `
    <span
        class="${CSS.icon} ${CSS.icon}--${theme} icon__${icon}"
        data-theme-name="${theme}"
    >
        ${ICONS[icon]}
    </span>
  `
}

function getIconsHtml() {
  return Object.entries(THEMES).reduce((iconsHtml, theme) => {
    return iconsHtml + getIconHtml(theme[0], theme[1].icon)
  }, '')
}

export default class Theme {
  constructor(documentRoot, container) {

    this.MQ = matchMedia(THEME_MQ)
    this.supportAuto = supportColorSchemeMQ()

    this.root = documentRoot
    this.container = container

    this.themeColorMeta = document.head.querySelector('meta[name="theme-color"]')

    this.list = Object.keys(THEMES)

    setTimeout(() => {
      this.init()
    }, 50) //
  }

  loadPreference() {
    return idbLoad('theme', null)
  }

  savePreference(preference) {
    idbSave('theme', preference)
  }

  getNameFromCSS() {
    let bgColor = getComputedStyle(this.root).getPropertyValue('--bg').trim()
    return Object.entries(THEMES).find(theme => bgColor == theme[1].themeColor)[0]
  }

  set(preference = 'auto') {
    this.root.classList.remove(CSS.themeSet, THEMES[this.current].CSS)
    if (preference == 'auto') {
      this.isAuto = true
      this.current = this.getNameFromCSS()
    } else {
      this.isAuto = false
      this.current = preference
      this.root.classList.add(CSS.themeSet, THEMES[preference].CSS)
    }

    this.themeColorMeta.content = THEMES[this.current].themeColor
    this.updateLabel()
  }

  getLabelStr() {
    let label = THEMES[this.current].name
    if (this.isAuto) {
      return `Auto (${label})`
    }

    return label
  }

  updateLabel() {
    if ('name' in this) {
      this.name.innerHTML = this.getLabelStr()
    }
  }

  toggle() {
    this.currentIndex =
      this.currentIndex == this.list.length - 1
        ? 0
        : this.currentIndex + 1

    this.preference = this.list[this.currentIndex]
    this.set(this.preference)

    this.savePreference(this.list[this.currentIndex])
  }

  onTap(e) {
    if (!e.target.classList.contains(CSS.btn)) { return }
    e.preventDefault()

    this.toggle()
  }

  appendHtml() {
    this.container.innerHTML = `
      <button class="${CSS.btn}" type="button">
          <span class="${CSS.btnInner}">
              <span class="visually-hidden">Cycle through themes</span>
              <span class="${CSS.iconCtn}">${getIconsHtml()}</span>
          </span>
      </button>
      <span class="${CSS.name}">${this.getLabelStr()}</span>
    `

    this.name = this.container.getElementsByClassName(CSS.name)[0]
  }

  init() {
    this.current = this.getNameFromCSS()

    this.loadPreference().then(preference => {
      this.preference = preference

      this.isAuto =
        this.supportAuto
        && (typeof preference != 'string' || preference == 'auto')

      if (typeof preference != 'string') {
        preference = this.current
      } else {
        this.set(preference)
      }

      if (!this.isAuto) {
        this.currentIndex = this.list.indexOf(preference)
      }

      this.appendHtml()

      if (this.supportAuto) {
        this.list.push('auto')
        if (this.isAuto) {
          this.currentIndex = this.list.indexOf('auto')
        }

        this.MQ.addListener(() => {
          if (this.isAuto) {
            this.set()
          }
        })
      }
    })
  }
}
