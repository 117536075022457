<template>

    <label class="filter-txt" for="filter">
        <span class="filter-txt__label filter__label">
            <slot />
        </span>
        <span class="filter-txt__input-ctn fillable">
            <input
                class="filter-txt__input fillable__input"
                type="text"
                id="filter" name="filter"
                placeholder="Regions, comma separated"
                v-model="filter"
                @focusin="onFocusIn"
                :aria-label="$slots.default[0].text.trim()"
            >
            <span class="fillable__bg" />
        </span>
    </label>

</template>

<script>
export default {
  computed: {
    filter: {
      get () {
        return this.$store.state.filters.text.text
      },
      set (value) {
        this.$store.dispatch('text', value)
      },
    }
  },

  methods: {
    onFocusIn() {
      this.$store.dispatch('selectedOnly', false)
    }
  },
}
</script>
