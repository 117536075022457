<template>
    <div>
        <a
            v-if="regionsUrlParam != ''"
            class="share__link | truncated-text"
            :href="url" target="_blank" rel="noopener"
        >
            {{ url }}
        </a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['regionsUrlParam', 'url']),
}
</script>
