<template>
    <div class="theme" @click="onClick" />
</template>

<script>
import Theme from '../theme-switcher'

export default {
  data: function () {
    return {
      theme: null,
    }
  },

  mounted() {
    this.theme = new Theme(document.documentElement, this.$el)
  },

  methods: {
    onClick(e) {
      this.theme.onTap(e)
    },
  }
}
</script>
