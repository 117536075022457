<template>
    <div class="notification | flex-center">
        <template v-for="({ title, detail }, index) in messages">
            <h2 class="notification__message | h h2" :key="`notif-title-${index}`">{{ title }}</h2>
            <p class="notification__message notification__detail | h h3" :key="`notif-detail-${index}`">{{ detail }}</p>
        </template>

        <!-- Reload -->
        <button class="notification__btn | btn | flex-center" type="button" @click="reload">
            Reload
            <svg class="notification__btn__icon btn__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 50" width="22">
                <use class="sync-arrow-svg" xlink:href="#sync-arrow-path" />
            </svg>
        </button>
    </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },

  methods: {
    reload () {
      location.reload()
    },
  }
}
</script>
