export const DEFAULT_THEME = 'light'

export const THEME_MQ = `(prefers-color-scheme: ${DEFAULT_THEME})`

export const CSS = {
  themeSet: 'theme-set',
  name: 'theme__name',
  btn: 'theme__btn',
  btnInner: 'theme__btn__inner',
  icon: 'theme__icon',
  iconCtn: 'theme__icon__ctn',
}

export const THEMES = {
  dark: {
    name: 'Basic Black',
    CSS: 'basic-black',
    icon: 'moon',
    themeColor: 'hsl(0 0% 18%)',
  },
  light: {
    name: 'Basic White',
    CSS: 'basic-white',
    icon: 'sun',
    themeColor: 'hsl(0 0% 100%)',
  },
}

export const ICONS = {
  moon: `
      <svg
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 53"
          width="23" height="22"
          class="svg svg__moon"
          fill="none"
      >
          <path d="M49 34.2a19.2 19.2 0 0 1-12.2 4.4 19.7 19.7 0 0 1-19.5-19.9c0-7 3.6-13.2 9-16.7h-.2C12.8 2 2 13 2 26.5A24.3 24.3 0 0 0 26.1 51c10.7 0 19.7-7 22.9-16.8z" fill="currentColor" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  `,
  sun: `
      <svg
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49"
          width="25" height="25"
          class="svg svg__sun"
      >
          <circle class="sun__core" cx="24.5" cy="24.5" r="11.5" fill="currentColor"/>
          <line class="sun__beam" x1="24.5" x2="24.5" y2="9" stroke="currentColor" stroke-width="3"/>
          <line class="sun__beam" x1="24.5" y1="40" x2="24.5" y2="49" stroke="currentColor" stroke-width="3"/>
          <line class="sun__beam" x1="49" y1="24.5" x2="40" y2="24.5" stroke="currentColor" stroke-width="3"/>
          <line class="sun__beam" x1="9" y1="24.5" y2="24.5" stroke="currentColor" stroke-width="3"/>
          <line class="sun__beam" x1="41.8" y1="7.2" x2="35.5" y2="13.5" stroke="currentColor" stroke-width="3"/>
          <line class="sun__beam" x1="13.5" y1="35.5" x2="7.2" y2="41.8" stroke="currentColor" stroke-width="3"/>
          <line class="sun__beam" x1="41.8" y1="41.8" x2="35.5" y2="35.5" stroke="currentColor" stroke-width="3"/>
          <line class="sun__beam" x1="13.5" y1="13.5" x2="7.2" y2="7.2" stroke="currentColor" stroke-width="3"/>
      </svg>
  `,
  auto: `
      auto
  `,
}
