<template>
    <div class="filters" :class="{ 'filters--visible': allFiltersVisible }">

        <div class="filters__sticky">

            <filter-text
                class="filter"
                :class="{
                    'filter--narrowed': !allFiltersVisible,
                    'filter--disabled': allFiltersVisible && selectedOnly,
                }"
            >
                Region name(s) or code(s), comma-separated:
            </filter-text>

            <!-- Expand -->
            <button
                class="filters__btn filters__btn--open | flex-center"
                v-show="!allFiltersVisible"
                @click="toggleAllFiltersVisibility(true)"
            >
                <span class="filters__btn__inner">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 21" width="25">
                        <title>Filter</title>
                        <use xlink:href="#filter-path" />
                    </svg>
                </span>
            </button>

        </div>

        <div class="filters__collapsed">

            <selected-only class="filter" v-if="selectedRegions.length">Show</selected-only>

            <threshold class="filter" :class="{ 'filter--disabled': selectedOnly }">
                Minimum usage:
            </threshold>

            <!-- Collapse -->
            <button
                class="filters__btn filters__btn--close | flex-center"
                v-show="allFiltersVisible"
                @click="toggleAllFiltersVisibility(false)"
            >
                <span class="filters__btn__inner">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="17">
                        <title>Close filters</title>
                        <use xlink:href="#cross-path" />
                    </svg>
                </span>
            </button>

        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import FilterText from './FilterText.vue'
import SelectedOnly from './SelectedOnly.vue'
import Threshold from './Threshold.vue'

export default {

  components: {
    FilterText,
    SelectedOnly,
    Threshold,
  },

  computed: {
    ...mapState({
      allFiltersVisible: state => state.layout.allFiltersVisible,
      selectedOnly: state => state.filters.selectedOnly.selectedOnly,
    }),

    ...mapGetters(['selectedRegions']),
  },

  methods: mapActions(['toggleAllFiltersVisibility']),
}
</script>
