import collatorIndexOfMaker from 'locale-index-of'

export const canTranslate = 'DisplayNames' in Intl

export const userLanguages = [...new Set(
  navigator.languages.filter(language => language.substring(0, 2) != 'en')
)]

/**
 * Should be `export const altLanguage = userLanguages?.at(0)` but `Array.at`
 * support starts at Safari 15.4 and the polyfill is too heavy.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at
 */
export const altLanguage = userLanguages.length ? userLanguages[0] : null

/** Try to detect text direction. */
let localeDir = null
if (altLanguage && 'Locale' in Intl) {
  const altLocaleInfo = new Intl.Locale(altLanguage)
  localeDir = altLocaleInfo.textInfo?.direction
}

export { localeDir }

/**
 * Fetch language direction.
 *
 * @param {string} lang
 * @returns {string}
 */
export const fetchLanguageDir = async lang => fetch(`/api/langdir/${lang}`)
  .then(response => response.json())
  .catch(err => console.error(err))

/**
 * Create a collator.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator
 *
 * Filter out potentially `null` alternative language because the
 * `Intl.Collator` constructor only allows non-empty strings.
 */
const collator = new Intl.Collator(['en', altLanguage].filter(locale => locale), {
  sensitivity: 'base',
  usage: 'search',

  /**
   * `ignorePunctuation` could be used, but makes `locale-index-of` gives wrong
   * results. For example, it fails to find `Emi` in `United Arab Emirates`.
   */
  // ignorePunctuation: true,
})

const collatorIndexOf = collatorIndexOfMaker(Intl)

/**
 * Check if a string belongs to another using collator algorithm. Unfortunately
 * not a part of the Intl API, though it’s an ongoing discussion since 2020:
 * https://github.com/tc39/ecma402/issues/506
 *
 * @param {string} haystack The string in which to look.
 * @param {string} needle The string to look for.
 * @param {Intl.Collator} intlCollator
 * @returns {boolean}
 */
export const collatorIncludes = (haystack, needle, intlCollator = collator) =>
  collatorIndexOf(haystack, needle, intlCollator) > -1
