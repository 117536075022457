// Approach for when IndexedDB is not available based on https://github.com/Sunappu-Dojo/burokku/pull/52/files

import { idbGet, idbSet } from './storage/idbLegacy'

/**
 * Load from IndexedDB, if IndexedDB is available.
 *
 * @param {string} key IndexedDB key.
 * @param {*} [defaultValue=undefined] Value returned when not found, `null` or
 *                                     `undefined`. Default to `undefined`.
 * @returns {Promise<*>}
 */
export const idbLoad = async (key, defaultValue = undefined) => idbGet(key, defaultValue)

/**
 * Save in IndexedDB, if IndexedDB is available.
 *
 * @param {string} key IndexedDB key.
 * @param {*} value
 */
export const idbSave = (key, value) => idbSet(key, value)
