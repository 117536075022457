import { collatorIncludes } from '../../helpers/intl'
import { strToRegExps, strInRegExps } from '../../helpers/regexp'
import favorites from './favorites'

const modules = {
  favorites,
}

const state = {
  all: [],
}

const mutations = {
  createRegions (state, regions) {
    state.all = regions.map(region => {
      region.data = 0
      return region
    })
  },

  attachData (state, regionsData) {
    state.all = state.all.map(region => {
      region.data = regionsData[region.code]
      return region
    })
  },

  toggleRegionSelection (state, region) {
    region.selected = !region.selected
  },
}

const actions = {
  toggleSelection ({ state, dispatch, commit }, code) {
    let region = state.all.find(region => region.code == code)

    commit('toggleRegionSelection', region)

    if (region.selected) {
      commit('addToFavorites', region.code)
    } else {
      commit('removeFromFavorites', region.code)
    }

    dispatch('saveFavorites')
    dispatch('updateHistory')

    if (state.favorites.codes.length == 0) {
      dispatch('selectedOnly', false)
    }
  },

  fetchData ({ rootState: { browsers }, dispatch, commit }) {
    return fetch(`/storage/data/${browsers.current.key}-${browsers.current.version}.json`)
      .then(response => response.json())
      .then(data => {
        commit('attachData', data.usage)
        dispatch('updateHistory')
      })
      .catch(error => { console.log(error) })
  },
}

const getters = {
  filteredRegions (state, getters, rootState) {

    // Don’t filter when only selected regions are shown.
    if (rootState.filters.selectedOnly.selectedOnly) {
      return getters.selectedRegions
    }

    const filterRegExps = strToRegExps(rootState.filters.text.text)

    const searchTerms = rootState.filters.text.text
      .split(',')
      .map(string => string.trim())
      .filter(term => term)

    return state.all.filter(region => {

      // String gathering region code, name and translated name.
      const regionStr = `${region.code} ${region.name} ${region.translatedName ?? ''}`

      // meet minimum usage threshold
      return region.data >= rootState.filters.threshold.threshold

        && (
          !filterRegExps.length // why? -_-

          // test RegExps against region code, name and translated name
          || strInRegExps(regionStr, filterRegExps)

          /**
           * A permissive way to test search terms against translated name.
           * For example: `États-Unis` will be found looking for `eta`,
           * which is not the case with the previous algorithm.
           */
          || (searchTerms.some(term => collatorIncludes(regionStr, term)))
        )
    })
  },
}

export default { modules, state, mutations, actions, getters }
