const state = {
  selectedOnly: false,
}

const mutations = {
  selectedOnly (state, value) {
    state.selectedOnly = value
  },
}

const actions = {
  selectedOnly ({ commit }, value) {
    commit('selectedOnly', value)
  },
}

export default { state, mutations, actions }
