const state = {
  text: '',
}

const mutations = {
  text (state, text) {
    state.text = text
  },
}

const actions = {
  text ({ commit }, text) {
    commit('text', text)
  },
}

export default { state, mutations, actions }
