// From `domain?param=ab,cd,ef` to `['ab','cd','ef'] || undefined

export function urlParamToArray(param, separator = ',') {
  const url = new URL(document.location.href)
  return url.searchParams.get(param)?.split(separator)
}

// Join array of `paramsName=paramsList`

const joinUrlParams = (params = []) =>
  params.some(param => param.length > 0) ? `?${params.join('&')}` : ''

// Create absolute URL from path and search parameters

export function createUrl(path = '', search = []) {
  return `${document.location.origin}/${path}${joinUrlParams(search)}`
}

export { joinUrlParams }
