/**
 * Create RegExp array from comma-separated string.
 *
 * @param {string} string
 * @param {string} separator
 * @return {RegExp|boolean}
 */
export function strToRegExps (string, separator = ',') {
  let regexps = string
    .split(separator)

    // create RegExp from each
    .map(string => {
      string = string.trim()
      if (!string.length) { return false }

      let stringRegExp = false

      // Ensure RegExp validity (e.g. using `[`)
      try {
        stringRegExp = RegExp(string, 'i')
      } catch (e) {
        // do nothing and continue
      }
      return stringRegExp
    })

    .filter(regexp => !!regexp) // remove non RegExp elements

  // Remove duplicated RegExps
  return [...new Set(regexps)]
}

/**
 * Test if a string matches at least one of the RegExps.
 *
 * @param {string} string
 * @param {RegExp[]} regexps
 * @returns {boolean}
 */
export function strInRegExps(string, regexps) {
  return regexps.some(regexp => regexp.test(string))
}
