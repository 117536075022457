const state = {
  allFiltersVisible: false,
}

const mutations = {
  setAllFiltersVisible (state, value) {
    state.allFiltersVisible = value
  },
}

const actions = {
  toggleAllFiltersVisibility ({ commit }, value) {
    commit('setAllFiltersVisible', value)
  },
}

export default { state, mutations, actions }
