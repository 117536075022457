const state = {
  month: false,
}

const mutations = {
  setMonth (state, value) {
    state.month = value
  },
}

const getters = {
  isDifferentMonth: ({ month }) => fetchedMonth =>
    month != false && fetchedMonth != null && fetchedMonth != month
}

export default { state, mutations, getters }
