import { idbSave } from '../../helpers/idb'

const state = {
  codes: [],
}

const mutations = {
  createFavorites (state, codes) {
    state.codes = codes
  },

  addToFavorites (state, code) {
    state.codes.push(code)
  },

  removeFromFavorites (state, codeToRemove) {
    let index = state.codes.findIndex(code => code == codeToRemove.code)
    state.codes.splice(index, 1)
  },
}

const actions = {
  saveFavorites ({ state }) {
    idbSave('selected-regions-codes', state.codes)
  },
}

const getters = {
  regionsUrlParam ({ codes }) {
    return codes.length > 0 ? `regions=${codes.join(',')}` : ''
  },

  selectedRegions (state, getters, rootState) {
    return rootState.regions.all.filter(region => region.selected)
  },
}

export default { state, mutations, actions, getters }
