<template>

    <fieldset>
        <div class="selected-only">

            <p class="filter__label">Show</p>

            <div class="selected-only__btns btn-group">

                <!-- All -->
                <label
                    class="btn btn--grouped | flex-center noselect"
                    :class="{ 'btn--selected': !selectedOnly }"
                    for="show-all-regions"
                >
                    All regions
                    <input
                        type="radio"
                        v-model="selectedOnly" value="false"
                        id="show-all-regions" name="selected-only"
                    >
                </label>

                <!-- Favorites -->
                <label
                    class="btn btn--grouped | flex-center noselect"
                    :class="{ 'btn--selected': selectedOnly }"
                    for="show-selected-regions"
                >
                    My
                    <span class="btn__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115 110" width="14">
                            <title>favorites</title>
                            <use
                                class="star-svg"
                                :class="{ 'star-svg--plain': selectedOnly }"
                                xlink:href="#star-path" clip-path="url(#star-clip)"
                            />
                        </svg>
                    </span>

                    <input
                        type="radio"
                        v-model="selectedOnly" value="true"
                        id="show-selected-regions" name="selected-only"
                    >
                </label>

            </div>

        </div>
    </fieldset>

</template>

<script>
export default {
  computed: {
    selectedOnly: {
      get () {
        return this.$store.state.filters.selectedOnly.selectedOnly
      },
      set (value) {
        this.$store.dispatch('selectedOnly', value == 'true')
      },
    }
  },
}
</script>
