<template>

    <div class="x-space">
        <div class="sorters regions__sorters" v-if="sortedRegions.length">
            <p class="sorters__title">Sort by</p>
            <sort-button sort-key="name" :sorted-by="sortedBy" @sort="sortBy">A-Z</sort-button>
            <sort-button sort-key="data" :sorted-by="sortedBy" sort-order="desc" @sort="sortBy">Usage</sort-button>
        </div>

        <ul class="regions__list" v-if="sortedRegions.length">
            <region
                v-for="region in sortedRegions" :key="region.code"
                :region="region"
            />
        </ul>
    </div>

</template>

<script>
import sortOn from 'sort-on'
import Region from './Region.vue'
import SortButton from './SortButton.vue'

export default {
  components: {
    Region,
    SortButton,
  },

  props: {
    regions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      sortedBy: 'data',
      direction: '-',
    }
  },

  computed: {
    sortedRegions() {
      return sortOn(this.regions, this.direction + this.sortedBy)
    },
  },

  methods: {
    sortBy(field = this.sortedBy, direction) {
      this.sortedBy = field
      this.direction = direction
    },
  },
}
</script>
