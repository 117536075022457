import sortOn from 'sort-on'
import { getBrowserFromUrl, normalizeBrowserVersions } from './helpers'

const defaultBrowser = {
  key: 'ie',
  version: 11,
}

const state = {
  browsers: [],
  cachedBrowsers: null,
  current: defaultBrowser,
}

const mutations = {
  createBrowsers (state, value) {
    state.browsers = value
  },

  setBrowser (state, browser) {
    state.current = browser
  },

  setCachedBrowsers (state, value) {
    state.cachedBrowsers = value
  },
}

const actions = {
  createBrowsers({ commit }, payload) {
    let browsers = []

    for (let key in payload) {
      payload[key].versions = normalizeBrowserVersions(payload[key].versions)
      payload[key].key = key
      browsers.push(payload[key])
    }

    browsers = sortOn(browsers, ['type', '-minor', 'name'])

    commit('createBrowsers', browsers)
  },

  setBrowser({ commit, dispatch, getters: { availableBrowsers } }, payload) {
    function findBrowser(browserKey) {
      return availableBrowsers.find(({ key }) => key == browserKey)
    }

    /**
     * Detect the browser with one of these, by priority:
     * - payload: when using browser selector;
     * - URL;
     * - default if URL is malformed
     */
    let browser = payload ?? (getBrowserFromUrl() || defaultBrowser)

    // Get browser details, fall back to default on wrong browser key.
    let browserDetails = findBrowser(browser.key)
    if (!browserDetails) {
      browser = defaultBrowser
      browserDetails = findBrowser(browser.key)
    }

    // If browser version is not found, fall back on the most recent one.
    const version = browserDetails.versions.find(({ version }) => version == browser.version)
    if (!browser.version || !version) {
      browser.version = browserDetails.versions[0].version
    }

    commit('setBrowser', browser)

    if (payload) {
      dispatch('fetchData')
    }
  },

  requestCachedBrowsers() {
    if (navigator.serviceWorker?.controller) {
      navigator.serviceWorker.controller.postMessage({ action: 'requestCachedBrowsers' })
    }
  },

  setCachedBrowsers({ state, commit }, urls) {
    /*
     * RegExp grabbing browser data filenames.
     *
     * Filenames looks like `ie-11.json` or `ios_saf-13.0-13.1.json`.
     *
     * ([a-z|_]*)     capturing group #1: browser key (`ie`, `ios_saf`…)
     * -              separate the two capturing groups
     * (.{1,})        capturing group #2: browser version (`11`, `13.0-13.1`…)
     * .json          file extension
     */
    const urlRegex = new RegExp(/([a-z|_]*)-(.{1,}).json/)

    const browsers = urls.reduce((browsersDetail, url) => {
      let browser = urlRegex.exec(url)

      if (browser[1] in browsersDetail) {
        browsersDetail[browser[1]].versions.push(browser[2])
      } else {
        browsersDetail[browser[1]] = {
          ...state.browsers[browser[1]],
          versions: [browser[2]]
        }
      }

      return browsersDetail
    }, {})

    commit('setCachedBrowsers', browsers)
  },
}

const getters = {
  browserPathname (state) {
    return `b/${state.current.key}/${state.current.version}`
  },

  currentBrowser (state) {
    const name =
      state.browsers.length > 0
        ? state.browsers.find(({ key }) => key == state.current.key).name
        : state.current.key

    return {
      key: state.current.key,
      name,
      version: state.current.version,
    }
  },

  availableBrowsers (state, getters, rootState) {
    return rootState.navigator.online || !state.cachedBrowsers
      ? state.browsers
      : state.browsers
        .filter(browser => state.cachedBrowsers[browser.key])
        .map(browser => ({
          ...browser,
          versions: browser.versions
            .filter(version => state.cachedBrowsers[browser.key].versions.some(
              cachedVersion => cachedVersion == version.version
            )),
        }))
  },

  browsersByType (state, getters) {
    return (type) => getters.availableBrowsers.filter(browser => browser.type == type)
  },
}

export default { state, mutations, actions, getters }
