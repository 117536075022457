import Vue from 'vue'
import Vuex from 'vuex'

import browsers from './browsers'
import date from './date'
import filters from './filters'
import layout from './layout'
import * as theNavigator from './navigator'
import regions from './regions'
import url from './url'
// import * as about from './about'

import { altLanguage, canTranslate } from '../helpers/intl'
import { urlParamToArray } from '../helpers/url'
import { idbLoad } from '../helpers/idb'

const metadataFileSuffix = canTranslate ? '-light' : ''

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV != 'production',

  modules: {
    url,
    layout,
    navigator: theNavigator.default,

    date,
    browsers,
    regions,
    filters,

    // about,
  },

  actions: {
    fetchMetadata ({ dispatch, commit }) {
      return fetch(`/storage/metadata${metadataFileSuffix}.json`)
        .then(response => response.json())
        .then(async ({ browsers, date, regions, regions_codes }) => {
          commit('setMonth', date)
          dispatch('createBrowsers', browsers)

          let selectedRegionsCodes = []

          /**
           * Load favorites from URL or from IndexedDB,
           * and fallback to `[]` when no favorites.
           */
          let favoriteRegionsCodes = urlParamToArray('regions') ?? await idbLoad('selected-regions-codes', [])

          if (canTranslate) {
            const regionsInEnglish = new Intl.DisplayNames('en', { type: 'region' })

            let regionsInOtherLanguage = ''
            if (altLanguage) {
              regionsInOtherLanguage = new Intl.DisplayNames(altLanguage, { type: 'region' })
            }

            regions = regions_codes.map(region => {
              if (typeof region == 'string') {
                region = {
                  code: region,
                  name: regionsInEnglish.of(region.toUpperCase()),
                  translatedName: altLanguage ? regionsInOtherLanguage.of(region.toUpperCase()) : '',
                }
              }

              region.selected = favoriteRegionsCodes.includes(region.code)
              if (region.selected) {
                selectedRegionsCodes.push(region.code)
              }

              return region
            })
          } else {
            regions = Object.entries(regions).map(region => {
              let selected = favoriteRegionsCodes.includes(region[0])
              if (selected) {
                selectedRegionsCodes.push(region[0])
              }

              return {
                code: region[0],
                name: region[1],
                selected,
              }
            })
          }

          commit('createRegions', regions)
          commit('createFavorites', selectedRegionsCodes)

          dispatch('setBrowser')
          dispatch('updateHistory')

          if (selectedRegionsCodes.length > 0) {
            dispatch('selectedOnly', true)
          }
        })
        .catch(error => { console.log(error) })
    },
  },
})

export default store
