import text from './text'
import threshold from './threshold'
import selectedOnly from './selected-only'

const modules = {
  text,
  threshold,
  selectedOnly,
}

const actions = {
  loadFilters ({ dispatch }) {
    dispatch('loadThreshold')
  },
}

export default { modules, actions }
