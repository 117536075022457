import { idbLoad, idbSave } from '../helpers/idb'
import { altLanguage, fetchLanguageDir, localeDir } from '../helpers/intl'

const state = {
  online: true,
  altLanguageDir: localeDir,
}

const mutations = {
  setOnline (state, value) {
    state.online = value
  },

  setAltLanguageDir (state, value) {
    state.altLanguageDir = value
  },
}

const actions = {
  setOnline({ state, commit, dispatch }) {
    commit('setOnline', navigator.onLine)

    if (!state.online) {
      dispatch('requestCachedBrowsers')
    }
  },

  async loadAltLanguageDir({ commit }) {
    if (!altLanguage || localeDir) { return }

    /**
     * Load previously saved locales directions from IndexedDB.
     * A found object looks like `{ ar: 'rtl', fr: 'ltr'}`.
     */
    const directions = await idbLoad('languages-text-direction', {})

    // Direction found in IndexedDB or fetched from an endpoint.
    const direction = directions[altLanguage] ?? await fetchLanguageDir(altLanguage)

    // Update store
    commit('setAltLanguageDir', direction ?? 'auto')

    // Save in IndexedDB if direction is found.
    if (direction) {
      directions[altLanguage] = direction
      idbSave('languages-text-direction', directions)
    }
  },
}

export default { state, mutations, actions }
