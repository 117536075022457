import { idbLoad, idbSave } from '../../helpers/idb'
import { clamp, round } from '../../helpers/math'

const DEFAULT = 0

const state = {
  threshold: DEFAULT,
}

const mutations = {
  threshold (state, threshold) {
    state.threshold = clamp(round(threshold, 2), 0, 100)
  },
}

const actions = {
  threshold ({ dispatch, commit, rootState }, threshold) {
    commit('threshold', threshold)
    dispatch('saveThreshold', threshold)

    if (rootState.filters.selectedOnly.selectedOnly) {
      dispatch('selectedOnly', false)
    }
  },

  saveThreshold (context, threshold) {
    idbSave('threshold', threshold)
  },

  loadThreshold ({ commit }) {
    idbLoad('threshold', DEFAULT).then(threshold => commit('threshold', threshold))
  },
}

export default { state, mutations, actions }
