<template>

    <li class="region">
        <small class="region__code">{{ code }}</small>
        <span class="region__name">
            {{ name }}
            <small
                v-if="translatedName && name != translatedName"
                class="region__translatedName"
                :lang="altLanguage"
                :dir="altLanguageDir"
            >
                {{ translatedName }}
            </small>
        </span>
        <span class="region__usage">{{ formattedData }}</span>

        <!-- Add/remove from favorites -->
        <button
            type="button"
            class="region__star"
            @click="toggleSelection(code)"
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115 110" width="17">
                <title v-show="!selected">Add to favorites</title>
                <title v-show="selected">Remove from favorites</title>
                <use
                    class="star-svg" :class="{ 'star-svg--plain': selected }"
                    xlink:href="#star-path" clip-path="url(#star-clip)"
                />
            </svg>
        </button>
    </li>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import { altLanguage } from '../../helpers/intl'
import { round } from './../../helpers/math'

export default {
  props: {
    region: {
      type: Object,
      required: true,
    },
  },

  data() { return this.region },

  computed: {
    ...mapState({
      altLanguageDir: state => state.navigator.altLanguageDir,
    }),

    formattedData() {
      return round(this.data, 2).toFixed(2)
    },
  },

  created() {
    this.translatedName = this.region.translatedName
    this.altLanguage = altLanguage
  },

  methods: mapActions(['toggleSelection']),
}
</script>
