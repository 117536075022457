<template>

    <fieldset class="browser__fieldset">

        <legend class="visually-hidden">Select version</legend>

        <div class="browser__group">
            <h3 class="browser__group-title | h h2">Version</h3>

            <ul class="browsers__list" :style="`--longest: ${longestVersionLength}`">
                <li
                    class="browser__item browser__version"
                    :class="{
                        'browser__item--date-visible': version.adjustedStatus || (version.status && version.status != 'retired'),
                        'browser__item--selected': version.version == selectedVersion
                    }"
                    v-for="version in versions" :key="version.version"
                >
                    <span class="browser__year | h h2">
                        <span v-if="version.yearTitle">{{ version.yearTitle }}</span>
                    </span>
                    <label class="browser__label" :for="`browser-${version.version}`">
                        <input
                            class="browser__input"
                            type="radio"
                            :checked="version.version == selectedVersion"
                            :id="`versions-${version.version}`" name="versions"
                            :selected="version.version == selectedVersion" :value="version.version"
                            @change="selectVersion"
                        >
                        <span class="browser__name">{{ version.version }}</span>
                    </label>
                    <p
                        class="browser__status"
                    >
                        <span v-if="version.adjustedStatus || (version.status && version.status != 'retired')">
                            {{ version.adjustedStatus || version.status }}
                        </span>
                        <span class="visually-hidden">release:</span>
                        <time
                            v-if="version.release_date"
                            class="browser__date"
                            :datetime="formatDateAttr(version.release_date)"
                        >
                            <small>
                                {{ formatDate(version.release_date) }}
                            </small>
                        </time>
                    </p>
                </li>
            </ul>
        </div>

    </fieldset>

</template>

<script>
import { datetime } from 'datetime-attribute'
import { mapState } from 'vuex'

export default {
  props: {
    versions: {
      type: Array,
      required: true,
    },
    selectedVersion: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      dataDate: null,
      nextDataDate: null,
      longestVersionLength: 'auto',
    }
  },

  computed: mapState({
    month: state => state.date.month,
  }),

  created() {
    this.setDates()
    this.initTags()
  },

  mounted() {
    this.setLongestVersion()
  },

  methods: {
    selectVersion(e) {
      this.$emit('selectVersion', e)
    },

    formatDate(releaseDate) {
      if (!releaseDate) { return '' }

      const yearOption = releaseDate > this.nextDataDate ? { year: 'numeric' } : ''

      return new Intl.DateTimeFormat('en-US', {
        day: 'numeric', month: 'long', ...yearOption
      }).format(releaseDate)
    },

    formatDateAttr(date) {
      return date ? datetime(date) : ''
    },

    /**
     * @todo: Should be moved to the store.
     */
    initTags() {
      const versionsWithDate = this.versions.filter(version => version.release_date)

      if (!versionsWithDate.length) { return }

      // Mark current, today and future versions.
      const versions = {
        today: versionsWithDate.find(version => version.status == 'current'),

        /*
         * The versions used during the data month are the one release before
         * and the one(s) released during the data month. Set avoids duplication.
         */
        current: new Set([

          // First during or before the data month.
          versionsWithDate.find(version => version.release_date < this.dataDate),

          // Released during the data month.
          ...versionsWithDate.filter(version =>
            version.release_date.getFullYear() == this.dataDate.getFullYear()
              && version.release_date.getMonth() == this.dataDate.getMonth()
          ),
        ]),

        future: versionsWithDate.filter(version =>
          version.release_date > this.nextDataDate
            && version.status != 'current'
        ),
      }

      /*
       * Years separator
       */
      let upperYear = versions.today
        ? versions.today.release_date?.getFullYear()
        : versionsWithDate[0].release_date.getFullYear()

      const oldestYear = versionsWithDate[versionsWithDate.length - 1].release_date.getFullYear()

      while (upperYear >= oldestYear) {
        const yearVersion = this.versions.find(version => version.release_date?.getFullYear() == upperYear)

        if (yearVersion) {
          yearVersion.yearTitle = upperYear
        }
        upperYear--
      }

      if (versions.future.length) {
        const futureVersion = this.versions.find(version => version.version == versions.future[0].version)
        futureVersion.yearTitle = 'Future'
      }

      versions.current.forEach(currentVersion => {
        const current = this.versions.find(version => version.version == currentVersion.version)
        current.adjustedStatus = `current in ${this.month}`
      })

      if (versions.today) {
        const todayVersion = this.versions.find(version => version.version == versions.today.version)
        todayVersion.adjustedStatus = 'current today'
        todayVersion.yearTitle = todayVersion.release_date.getFullYear()
      }
    },

    setDates() {
      /*
       * Safari 13.1 understands `01 April 2020`, but fails on `April 2020`.
       * https://twitter.com/meduzen/status/1263536913551630341
       */
      this.dataDate = new Date('01 ' + this.month)

      // last day of this.dataDate month
      this.nextDataDate = new Date(this.month)
      this.nextDataDate.setMonth(this.nextDataDate.getMonth() + 1) // jump to next month
      this.nextDataDate.setDate(0) // go to last day of previous month
    },

    setLongestVersion() {
      let longest = 0

      document.querySelectorAll('.browser__label').forEach(versionEl => {
        const width = versionEl.getBoundingClientRect().width
        if (width > longest) {
          longest = width
        }
      })

      this.longestVersionLength = longest + 'px'
    }
  },
}
</script>
