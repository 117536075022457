<template>

    <fieldset class="browser__fieldset">

        <legend class="visually-hidden">Select a browser</legend>

        <div class="browser__fieldset-inner">

            <!-- Desktop -->
            <div class="browser__group">
                <h3 class="browser__group-title | h h2">Desktop</h3>
                <ul class="browsers__list">
                    <li class="browser__item" v-for="{ key, name } in desktopBrowsers" :key="key">
                        <label class="browser__label" :for="`browser-${key}`">
                            <input
                                class="browser__input"
                                type="radio"
                                :checked="key == selectedBrowser"
                                :id="`browser-${key}`" name="browser"
                                :selected="key == selectedBrowser" :value="key"
                                @click="selectBrowser"
                            >
                            <span class="browser__name">{{ name }}</span>
                        </label>
                    </li>
                </ul>
            </div>

            <!-- Mobile -->
            <div class="browser__group">
                <h3 class="browser__group-title | h h2">Mobile</h3>
                <ul class="browsers__list">
                    <li class="browser__item" v-for="{ key, name } in mobileBrowsers" :key="key">
                        <label class="browser__label" :for="`browser-${key}`">
                            <input
                                class="browser__input"
                                type="radio"
                                :checked="key == selectedBrowser"
                                :id="`browser-${key}`" name="browser"
                                :selected="key == selectedBrowser" :value="key"
                                @click="selectBrowser"
                            >
                            <span class="browser__name">{{ name }}</span>
                        </label>
                    </li>
                </ul>
            </div>

        </div>

    </fieldset>

</template>

<script>
export default {
  props: {
    browsers: {
      type: Array,
      required: true,
    },
    selectedBrowser: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    desktopBrowsers() {
      return this.$store.getters.browsersByType('desktop')
    },

    mobileBrowsers() {
      return this.$store.getters.browsersByType('mobile')
    },
  },

  methods: {
    selectBrowser(e) {
      this.$emit('selectBrowser', e)
    },
  }
}
</script>
