<template>

    <button
        class="btn | flex-center" :class="cssClasses"
        type="button"
        @click="onClick"
    >
        <slot />
        <span class="btn__icon | flex-center">
            <svg class="sorter__arrow sorter__arrow--asc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 15" width="8">
                <title v-if="isAsc">ASC</title>
                <use class="arrow-svg" xlink:href="#arrow-path" />
            </svg>
            <svg class="sorter__arrow sorter__arrow--desc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 15" width="8">
                <title v-if="isDesc">DESC</title>
                <use class="arrow-svg" xlink:href="#arrow-path" />
            </svg>
        </span>
    </button>

</template>

<script>
export default {
  props: {
    sortKey: {
      type: String,
      required: true,
    },
    sortedBy: {
      type: String,
      required: true,
    },
    sortOrder: {
      type: String,
      default: '',
    },
  },

  data: function () {
    return {
      order: this.sortOrder,
    }
  },

  computed: {
    defaultOrder() { return this.sortOrder == 'desc' ? '-' : '' },

    isCurrentSortKey() { return this.sortedBy == this.sortKey },

    isAsc() { return this.isCurrentSortKey && this.order == '' },
    isDesc() { return this.isCurrentSortKey && this.order == '-' },

    cssClasses: function () {
      return {
        'btn--selected': this.isCurrentSortKey,
        'sorter--selected': this.isCurrentSortKey,
        'sorter--asc': this.isAsc,
        'sorter--desc': this.isDesc,
      }
    },
  },

  created() {
    this.order = this.defaultOrder
  },

  methods: {
    setOrder() {
      if (!this.isCurrentSortKey) {
        this.order = this.defaultOrder
      } else {
        this.order = !this.order.length ? '-' : ''
      }
    },

    onClick() {
      this.setOrder()
      this.$emit('sort', this.sortKey, this.order)
    },
  },
}
</script>
